
.react-datepicker {
    font-family: 'Your-Metronic-Font', sans-serif;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.react-datepicker__day--outside-month {
    color: #888;
}

.react-datepicker__day--today {
    font-weight: bold;
}

.react-datepicker__day--selected {
    background-color: #36a2eb;
    color: #fff;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
    margin: 0;
}

.react-datepicker__year-dropdown-container,
.react-datepicker__month-dropdown-container {
    margin: 0;
}

.react-datepicker__year-option,
.react-datepicker__month-option {
    padding: 8px;
&:hover {
     background-color: #f2f2f2;
 }
}

/* Stilizacija za react-select */
.react-select-container {
    font-family: 'Your-Metronic-Font', sans-serif;
    width: 100%;
}

.react-select__control {
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 36px;
}

.react-select__menu {
    font-family: 'Your-Metronic-Font', sans-serif;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.react-select__option {
    padding: 8px;
&:hover {
     background-color: #f2f2f2;
 }
}

.react-select__option--is-selected {
    background-color: #36a2eb;
    color: #fff;
}

/* Dodatni stil za odvajanje između react-datepicker i react-select */
.datepicker-select-separator {
    margin-top: 16px;
}

